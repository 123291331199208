import React, { useEffect, useState } from 'react';
import AppleDeveloperLogo from '../../../assets/img/logos/apple-developer.png';
import GooglePlayLogo from '../../../assets/img/logos/googleplay.svg';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../contexts/user.context';
import { planIds, availablePlans, PLATFORMS, platformsIntegration, storeStatus, planPeriodicity } from '../../../utils/constants';
import BillingLayout from '../../../layouts/billing';
import { usePlan } from '../../../contexts/plan.context';
import _ from 'lodash';
import PlanCard from './PlanCard';
import PeriodSelector from './PeriodSelector';
import wixLogo from '../../../assets/img/platforms/wix.png'
import config from '../../../utils/config';

const useStyles = makeStyles(() => ({
    warning: {
        fontWeight: 700,
        marginBottom: 15
    },
    disclaimer: {
        color: '#858585',
        fontSize: 16,
        padding: '0 16px 40px 16px',
        textAlign: 'center',
        "& p": {
            marginBottom: 10
        },
        "& img": {
            display: 'block',
            margin: '1em auto 0',
            height: 'auto',
            verticalAlign: 'middle',
            '@media (min-width: 960px)': {
                display: 'inline-block',
                margin: '0 0 0 30px'
            }
        },
        "& .dev-account-wrapper": {
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            '@media (min-width: 960px)': {
                display: 'inline-block',
            }
        },
        "& .dev-account": {
            display: 'block',
            "& span": {
                marginLeft: 5
            },
            '@media (min-width: 960px)': {
                display: 'inline-block',
            }
        }
    },
    plansGrid: {
        display: "flex",
        maxWidth: "100%",
        padding: "30px 20px",
        overflowX: 'auto'
    },
    wixLogo: {
        height: '100%',
        width: '100%',
        maxWidth: 500
    }
}));

export default function Pricing({ fullScreen }) {
    const navigate = useNavigate();

    const pricingRedirections = localStorage.getItem('pricingRedirections');

    const { setPlan } = usePlan();

    const { user } = useUser();
    const classes = useStyles();

    const [periodicity, setPeriodicity] = useState(planPeriodicity.MONTHLY);

    useEffect(() => {
        if (user?.store?.status === storeStatus.ACTIVE) {
            navigate('/')
            return;
        }
    }, [user]);

    useEffect(() => {
        if (pricingRedirections && pricingRedirections > 0) {
            navigate('/')
        } else {
            setTimeout(() => {
                if (user?.store?.platformId === PLATFORMS.WIX && pricingRedirections) {
                    localStorage.setItem('pricingRedirections', 1);
                    window.open(config.WIX_CHECKOUT_URL, "_self")
                }
            }, 3000)
        }
    }, [])

    const handleNavigation = async (planName) => {
        if (platformsIntegration.includes(user.store?.platformId)) {
            if (planName == planIds.PLAN_PRO_MONTHLY_2024) {
                switch (user.store?.platformId) {
                    case PLATFORMS.TRAY:
                        return navigate('/integration')
                }
            }
        }

        navigate('/hire')
    }

    const handlePlanSelect = (planType) => {
        const planName = availablePlans[planType][periodicity]

        if (planName) {
            setPlan(planName)
            handleNavigation(planName)
        }
    }

    if (user?.store?.platformId === PLATFORMS.WIX) {
        return (
            <BillingLayout fullScreen={fullScreen}>
                <Box height='100%' alignItems="center" justifyContent='center' flexDirection="column" display="flex">
                    <Grid item xs='auto' mt={2} width={10}>
                        <img className={classes.wixLogo} src={wixLogo} alt="logo" />
                    </Grid>

                    <Typography variant="h6" mt={5} mb={5}>
                        Redirecionando você para o Wix...
                    </Typography>

                    <CircularProgress />
                </Box>
            </BillingLayout>
        )
    }

    return (
        <BillingLayout fullScreen={fullScreen}>
            <Box textAlign='center' marginY={5}>
                {user.store?.status === "trial_ended" &&
                    <Typography variant="h3" className={classes.warning}>
                        Seu período de testes acabou!
                    </Typography>
                }

                <Typography variant="h5" sx={{ paddingX: '16px' }}>
                    Ative agora sua conta e publique os seus aplicativos
                </Typography>
            </Box>

            <Box alignItems="center" flexDirection="column" display="flex">
                <Box display="flex" flexDirection="row">
                    <PeriodSelector periodicity={periodicity} onSelect={(periodicity) => setPeriodicity(periodicity)} />
                </Box>
                {[PLATFORMS.LOJAINTEGRADA, PLATFORMS.WEBVIEW].includes(user?.store?.platformId)
                    ? <Box mt={4} mb={4}>
                        <PlanCard periodicity={periodicity} planType="basic" onSelect={handlePlanSelect} hideMissingFeatures />
                    </Box>
                    : <div className={classes.plansGrid}>
                        <PlanCard periodicity={periodicity} planType="basic" onSelect={handlePlanSelect} />
                        <PlanCard periodicity={periodicity} planType="pro" onSelect={handlePlanSelect} />
                    </div>
                }
            </Box>

            <div className={classes.disclaimer}>
                <p>Para publicar os seus aplicativos Android ou iOS é necessário criar as seguintes contas:</p>
                <div className="dev-account-wrapper">
                    <div className="dev-account">
                        <img src={GooglePlayLogo} width="134" height="30" alt="Google Play Developer Account" />
                        <span>U$25 (taxa única)</span>
                    </div>

                    <div className="dev-account">
                        <img src={AppleDeveloperLogo} width="134" height="30" alt="Apple Developer Account" />
                        <span>U$99/ano</span>
                    </div>
                </div>
            </div>
        </BillingLayout>
    )
}