import * as React from 'react'
import { PLATFORMS, plans } from '../utils/constants';

const UserContext = React.createContext()

function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`useUser must be used within a UserContext`);
  }
  return context;
}

function UserProvider({ children }) {
  const [user, setUser] = React.useState(null);

  const userPlan = user?.subscription?.planId ? plans[user?.subscription?.planId] : null

  const isBasic = [PLATFORMS.CONVERTR, PLATFORMS.LOJAINTEGRADA, PLATFORMS.WEBVIEW].includes(user?.store?.platformId) || (userPlan ? userPlan.isBasic : false)

  const isWebviewTheme = user?.store?.activeTheme === PLATFORMS.WEBVIEW

  return (
    <UserContext.Provider value={{ user, setUser, isBasic, isWebviewTheme }}>
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider, useUser }