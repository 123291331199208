import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Typography } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import useAppInfo from '../../../hooks/appInfo.hook';
import SaveButton from "../../../components/SaveButton";
import PublishingWarning from "../../../components/PublishingWarning";
import PublishRequiredDialog from "../../../components/PublishRequiredDialog";
import { useUser } from "../../../contexts/user.context";
import { mobilizeiStoresService } from '../../../services/mobilizei/stores.service';
import ImageUploader from '../../../components/ImageUploader'
import { mobileAppPlatforms } from '../../../utils/constants';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import DashboardLayout from '../../../layouts/Dashboard';
import { showApiError } from '../../../utils/errors';

export default function Images() {
    const navigate = useNavigate();
    const { isAppBeingPublished, isAppPublished, setPendingUpdateStatusOnStoreData } = useAppInfo();
    const { user, setUser } = useUser();

    const [isEditionDisabled, setIsEditionDisabled] = useState(false);
    const [showPublishRequiredDialog, setShowPublishRequiredDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsEditionDisabled(isAppBeingPublished(mobileAppPlatforms.ANDROID) || isAppBeingPublished(mobileAppPlatforms.IOS))
    }, [])

    const initialImages = {
        featureGraphicUrl: user?.store?.featureGraphicUrl,
        iconUrl: user?.store?.iconUrl,
        launchScreenUrl: user?.store?.launchScreenUrl
    }

    const [localImages, setLocalImages] = useState(initialImages);

    function onUploadSuccess(downloadUrl, urlParameterName) {
        setLocalImages({
            ...localImages,
            [urlParameterName]: downloadUrl
        })
    }

    function canBeSubmitted() {
        return (
            !_.isEqual(localImages["featureGraphicUrl"], user?.store?.featureGraphicUrl) ||
            !_.isEqual(localImages["iconUrl"], user?.store?.iconUrl) ||
            !_.isEqual(localImages["launchScreenUrl"], user?.store?.launchScreenUrl)
        );
    }

    const handleSaveButtonClick = () => {
        if (isAppPublished()) {
            setShowPublishRequiredDialog(true);
        } else {
            onSave();
        }
    }

    const onSave = () => {
        let data = localImages;

        // If the app is published, we need to change its status to pending update
        if (isAppPublished()) {
            data = setPendingUpdateStatusOnStoreData(data)
        }

        setIsLoading(true);
        setShowPublishRequiredDialog(false);

        // Remove empty properties
        data = _.omitBy(data, _.isEmpty);

        mobilizeiStoresService.updateStoreById(user?.store?.platformId, user?.store?.id, data)
            .then(() => {
                toast.success('Alterações salvas com sucesso!');

                // Update the local user data to match the data that was 
                // updated on the backend
                setUser({ ...user, store: _.merge(user.store, data) });

                // If the app is published, we redirect the user to the dashboard so he can
                // see the pending publishing warning
                if (isAppPublished()) {
                    navigate('/')
                }
            })
            .catch(e => {
                showApiError(e.error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <DashboardLayout
            Icon={ImageIcon}
            title="Imagens"
            subtitle="Ícone e outras imagens necessárias para a publicação do seu aplicativo"
        >
            <Box paddingBottom={8} paddingTop={3}>
                <PublishingWarning />

                <PublishRequiredDialog
                    open={showPublishRequiredDialog}
                    handleClose={() => {
                        setShowPublishRequiredDialog(false);
                    }}
                    handleSave={() => onSave()}
                />

                {/* App icon START */}
                <ImageUploader
                    description={
                        <div>
                            <Typography marginBottom={1} color="textSecondary">Envie o ícone do seu aplicativo no tamanho de 1024 x 1024 px em formato jpg ou png.</Typography>
                            <Typography marginBottom={1} color="textSecondary">Prefira trabalhar apenas com sua logomarca ou elementos de design mais simples. Evite textos uma vez que muitas vezes eles se tornam ilegíveis em dispositivos com resoluções menores.</Typography>
                            <Typography marginBottom={1} color="textSecondary">Não é permitido imagens com fundo transparente!</Typography>
                        </div>
                    }
                    disabled={isEditionDisabled}
                    filename="icon"
                    height={1024}
                    onUploadSuccess={onUploadSuccess}
                    title="Ícone"
                    uploadedImageUrl={localImages?.iconUrl}
                    urlParameterName="iconUrl"
                    width={1024}
                />
                {/* App icon END */}

                {/* Launch screen START */}
                <ImageUploader
                    description={
                        <div>
                            <Typography color="textSecondary">A tela de introdução aparece logo quando o aplicativo é aberto e deve ter um tamanho de 1080 x 1920 px em formato jpg ou png.</Typography>
                        </div>
                    }
                    disabled={isEditionDisabled}
                    filename="launch_screen"
                    height={1920}
                    onUploadSuccess={onUploadSuccess}
                    title="Tela de introdução"
                    uploadedImageUrl={localImages?.launchScreenUrl}
                    urlParameterName="launchScreenUrl"
                    width={1080}
                />
                {/* Launch screen END */}

                {/* Feature graphic START */}
                <ImageUploader
                    description={
                        <div>
                            <Typography color="textSecondary">A loja de aplicativos do Android (Play Store) pede uma imagem de banner de tamanho 1024 x 500 px, em formato jpg ou png, que será exibida na página de download do seu aplicativo.</Typography>
                        </div>
                    }
                    disabled={isEditionDisabled}
                    filename="playstore"
                    height={500}
                    onUploadSuccess={onUploadSuccess}
                    title="Banner da Play Store"
                    uploadedImageUrl={localImages?.featureGraphicUrl}
                    urlParameterName="featureGraphicUrl"
                    width={1024}
                />
                {/* Feature graphic END */}

                <SaveButton
                    isLoading={isLoading}
                    onCancel={() => setLocalImages(initialImages)}
                    onClick={handleSaveButtonClick}
                    show={canBeSubmitted()}
                />
            </Box>
        </DashboardLayout>
    )
}